<template>
    <ImageBlock v-bind="data" :class="CSSClasses" />
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const CSSClasses = computed(() => ['streamImageBlock', props.data.width ? `width-${props.data.width}` : null]);
</script>

<style lang="scss" scoped>
.width-large {
    @include media-query(tablet-mw) {
        width: 130%;
        transform: translateX(-50%);
        left: 50%;
        position: relative;
    }

    &.streamImageBlock {
        @include media-query(phone-mw) {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
</style>
